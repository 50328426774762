import {getFirestore,writeBatch,collection,doc,Timestamp,serverTimestamp,arrayUnion,deleteField} from 'firebase/firestore';
import {GETACC} from '../../getacc';
import {AddCredit} from "../index";
import moment from 'moment';


async function Init() {
	const db = getFirestore();
	const user = await GETACC();
	const batch = writeBatch(db);
	return {
		db,
		user,
		batch
	}
}


export const ApprovedVoucher = async (items,by) => {

	const {
		db,
		user,
		batch
	} = await Init()

  await Promise.all(items.map(async (item) => {
    item['voucher_id'] = item.id
	item['approvedby'] = by
    delete item['id']

    const Added = await AddCredit(item)
    const refC = doc(db, "account/" + user.id + "/payment_voucher/" + item['voucher_id']);
    batch.update(refC, {
			'status': 'approved',
			'approvedby': by,
			'updated': serverTimestamp(),
			'credit_id': Added.id
		})

  }));

  await batch.commit();

	return true

};


export const RejectPaymentVoucher = async (items) => {

	const {
		db,
		user,
		batch
	} = await Init()


	items.forEach(element => {
		const refC = doc(db, "account/" + user.id + "/payment_voucher/" + element.id);
		batch.update(refC, {
			status: 'reject',
			'updated': serverTimestamp()
		})
	});

	await batch.commit();

	return true

};





