import {Invoice} from "./encyrpt";

const header = {Accept: 'application/json','Content-Type': 'application/json'}



export const AddResident = async (data) => {
        return fetch('http://localhost:3000/addresident', {
            method: 'POST',
            headers: header,
            body: JSON.stringify(data)}
            ).then((response) => {
               return response.json()
            }).catch((error) => {
                return null
            });

  };

export const deActivated = async (data) => {
    return fetch('http://localhost:3000/updatestatus', {
        method: 'POST',
        headers: header,
        body: JSON.stringify(data)}
        ).then((response) => {
           return response.json()
        }).catch((error) => {
            return null
        });

};

export const RemoveResident = () => {
    return fetch('https://reactnative.dev/movies.json')
      .then((response) => response.json())
      .then((json) => {
        return json.movies;
      })
      .catch((error) => {
        console.error(error);
      });
};





export const WhatsappInvoice = async (data,type) => {
  const Name = data.company?data.company:data.name
  const Link = await Invoice(data.id,type)
  try {

    const response = await fetch('https://us-central1-donkeezacc-edb84.cloudfunctions.net/app/whatsapp_invoice', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "mobile":data.mobile,
        "id":data.id,
        "name":Name,
        "amount":"RM"+data.amount,
        "link":Link
      }),
    });

    const json = await response.json();
    return json;
  } catch (error) {
    console.log(error)
    console.error(error);
  }
};

export const GoogleMaps = async (address) => {

  try {
    const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+address+'&key=AIzaSyCPYW1lvp71s75Ar7Xm-M3NnMKaP11aRgY');
    const json = await response.json();
    console.log(json)
    return json.results[0];
  } catch (error) {
    console.error(error);
  } 

}