import React, { useState,useEffect } from 'react';
import { StyleSheet ,View} from 'react-native';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import {GetDocument } from "../../../../../assets/js/firebase";
import {Form,Text} from "../../../../../assets/component";
import { TouchableOpacity } from 'react-native-gesture-handler';
import { color } from 'react-native-reanimated';
import { isLoading } from 'expo-font';


export default function CreditNote(props) {

    const {set,values,credit,setcredit,amount} = props

    const [searchloading, setSearchLoading] = useState(null);


    useEffect(() => {



    }, []);



    async function SearchCreditNote(){
        if(values.creditnote){
            setSearchLoading(true)
            const Result = await GetDocument(values.creditnote,'credit_note')
            console.log(values.creditnote)
            setcredit(Result)
            setSearchLoading(false)
        }
    }

    

 
    const CreditNoteDetails = () => {
        const CreditBalance = credit.paid ? (credit.amount - credit.paid.reduce((n, {amount}) => n + amount, 0)) : credit.amount;
        const Balance = CreditBalance - parseFloat(amount)
        const Negative = Balance < 0 ? 'red':'blue'
        return (
            <View style={styles.bx}>
                <Text style={{fontWeight:'600',color:'blue'}}>#{credit.id}</Text>
                <View style={{flexDirection:'row'}}>
                    <View style={{flex:1}}><Text style={{fontWeight:'600'}}>{credit.name}</Text></View>
                    <TouchableOpacity onPress={()=>setcredit(null)}>
                            <Text>X</Text>
                    </TouchableOpacity>
                </View>
                <View style={{flexDirection:'row'}}>
                        <View style={{width:100}}>
                            <Text>Balance</Text>
                            <Text>Used</Text>
                            <Text>Balance After</Text>
                        </View>
                        <View style={{flex:1}}>
                            <Text>RM{CreditBalance}</Text>
                            <Text>{credit.paid  && credit.paid.length}</Text>
                            <Text style={{color:Negative}}>RM{Balance}</Text>
                        </View>
                </View>
                <Text>{ Balance < 0  && 'Adjust the amount not sufficient'}</Text>
            </View>
        )
    }

    
    if(values && values.payment !== 'credit note') return null

    if(credit)  return <CreditNoteDetails/>

    return (
        <Form 
                data={[
                        [{name:'creditnote',display:'Credit Note ID',flex:1,numeric:true,remark:'CR-XX (only Numbers)'},
                        {name:'search',color:'#dc3545',icon:'search',button:true,'onPress':SearchCreditNote,isLoading:searchloading}]
                    ]}
                value={values}
                set={set}
            />
    )



}

const styles = StyleSheet.create({
    bx:{
        borderWidth:1,
        backgroundColor:'#f8f8f8',
        borderRadius:5,
        padding:5,
        borderColor:'#eee',
        marginVertical:10,
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
});
