import {GETACC} from './getacc';
import CryptoJS from 'crypto-js';



export const Invoice = async (id,type) => {
  const user = await GETACC();
  var b64 = CryptoJS.AES.encrypt(id, 'donkeez').toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);
  // return 'http://localhost:3000/invoice/'+user.id+'/orders/'+eHex+'.pdf'
  return  'https://us-central1-donkeezacc-edb84.cloudfunctions.net/app/invoice/'+user.id+'/'+type+'/'+eHex+'.pdf'
};


export const PaymentVoucher = (acc,id,year) => {
  var b64 = CryptoJS.AES.encrypt(id, 'donkeez').toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);
   // return  'http://localhost:3000/voucher/'+acc.id+'/'+year+'/'+eHex+'.pdf'
  return  'https://us-central1-donkeezacc-edb84.cloudfunctions.net/app/voucher/'+acc.id+'/'+year+'/'+eHex+'.pdf'
};


