import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,ScrollView,TouchableOpacity} from 'react-native';
import {Modal,Form,Button,ListDown} from "../../../assets/component"
import COLORS from "../../../assets/component/colors";
import {AddPaymentVoucher,updateImageLink,getCreditTypes2} from "../../../assets/js/firebase";
import Storage from "../../../assets/js/storage";
import {GETACC} from '../../../assets/js/getacc';
import {UploadData} from "../../../assets/js/upload";
import SuggestList from './comp/suggest';


export default function AddNewVoucher(props) {

    const {add,update,setupdate} = props

    const BankLIST = ['CASH','MAYBANK','CIMB','BANK ISLAM','AFFIN','ALLIANCE','AMBANK','BANK RAKYAT','HLB','HSBC','MUAMALAT','OCBC','PBB','RHB','UOB','BSN']
    
    const [values,setValues] = useState({})
    const [category,setCategory] = useState({})

    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [credittype, setCreditType] = useState(null);
    const [categorylist, setCategoryList] = useState(null);



    useEffect(() => {

        getTypes()

    }, []);

    useEffect(() => {

        if(update){
            setValues(update)
            setCategory(update.category)
            setModalVisible(true)
        }

    }, [update]);


    async function getTypes(){
        const user = await GETACC();
        const CatName = user.id+'credit_cat'
        const Credit_Cat = await Storage.getData(CatName)
        const ParseCat = JSON.parse(Credit_Cat)
        if(ParseCat && ParseCat.list){
            console.log("Local")
            setCreditType(ParseCat.list);
            setCategoryList(ParseCat.cat_list)
        }else{
            const DataList  = await getCreditTypes2()
            Storage.storeData(CatName,JSON.stringify(DataList))
            setCreditType(DataList.list);
            setCategoryList(DataList.cat_list)
        }
    };

    async function StoreCatLocal(category,name){
        const user = await GETACC();
        const CatName = user.id+'credit_cat'
        const CatList = [...categorylist]
        const Newlist = credittype.filter(obj => obj.id !== category.id).concat(category)

        const exists = CatList.includes(name);
        if (!exists) {
            CatList.push(name);
        }

        setCreditType(Newlist)
        setCategoryList(CatList)
        const StoreVal = JSON.stringify({list:Newlist,cat_list:CatList})

        await Storage.storeData(CatName,StoreVal)
    }



        
    useEffect(() => {

        if(values.voucher){
         const SUM = values.voucher.reduce((n, {amount}) => n + parseFloat(amount), 0)
         setValues( prevValues => {return { ...prevValues,['amount']: SUM} })
        }
 
     }, [values.voucher]);




    async function Validate(){

        
        const validcheckNum = CheckNum(['amount'])
        const validCat= Validation(['name','category']);

        if(validcheckNum && validCat){
            setIsloading(true)
            const Result  = await AddPaymentVoucher({...values,'category':category})

            if(Result){

                    if(values.file){
                        const file = await UploadFile(Result.id)
                        Result['image'] = file
                    }

                StoreCatLocal(Result.category,category.category)
                add(Result)
                setModalVisible(false)
                setValues({})
                setCategory({})
            }
            setIsloading(false)
        }

    }

    async function UploadFile(id){
        const Route = 'payment_voucher/'+id
        const UploadedURL  = await UploadData('voucher',values.file,Route)
        await updateImageLink(Route,UploadedURL)
        return UploadedURL
    }


    function Validation(obj){
        var validated = true
        obj.map((name)=>{
            if(!category[name] || category[name].error){
                setCategory((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
                validated = false
            }
        })
        return validated
    }


    function CheckNum(obj){
        var validated = true
        obj.map((name)=>{
            if(isNaN(values[name])){
                setValues((prevState) => ({ ...prevState, [name]: {error:"Only Integer Value"} }));
                validated = false
            }
        })
        return validated
    }

      

      function changeHandler (val,type){
        setValues( prevValues => {
          return { ...prevValues,[type]: val}
        })
      }




    const CatCol = () => {

        const Arr = [
            [{name:'name',display:'Name'}],
            [{name:'name',display:'Name',content:<SuggestList data={credittype} values={category} set={(val)=>setCategory(val)}/>}],
            [{name:'category',display:'Category',listdownoverlay:categorylist,addnew:true},{name:'sub_category',display:'Sub Category'}]
        ]

        if(category.id) return Selected(category)
        return (
            <Form 
                        data={Arr}
                        value={category}
                        set={setCategory}
            />
        )
    }

    const Selected = (data) => {
        const {name,category,sub_category} = data
        return (
            <View style={styles.selected}>
                <View style={{flex:1}}>
                        <Text>{name}</Text>
                        <Text>{category}</Text>
                        <Text>{sub_category}</Text>
                </View>
                <TouchableOpacity style={{padding:5}} onPress={()=>setCategory({})}>
                        <Text>X</Text>
                </TouchableOpacity>
            </View>
        )
    }


    function CloseModal(){
        setModalVisible(false)
        setValues({})
        setCategory({})
        setupdate(null)
    }





    const AddModal = () => {

        return (
            <Modal title={'Add Voucher'} icon={'receipt'} visible={modalVisible} setVisible={CloseModal}>
                        <ScrollView >
                        <View style={{zIndex:99}}>

                            {CatCol()}
                        </View>
                            <Form 
                                data={[
                                            ['BANK'],
                                            [{name:'bank',display:'Bank',flex:1,listdown:BankLIST},{name:'account',display:"Account",flex:1}],
                                            [{name:'customer_id',display:'SSM/MYKAD'}],
                                        ]}
                                value={category}
                                set={setCategory}
                            />
                            <Form 
                                data={
                                            [
                                                [{name:'voucher',display:'Voucher',list:['name','amount'],others:true}],
                                                [{name:'amount',display:'Amount',flex:1,editable:false}],
                                                [{name:'date',display:'Date',date:true},{name:'remark',display:'Remark',flex:1}],
                                                [{name:'file',display:'File',file:true,size:25}],
                                            ]
                                    }
                                value={values}
                                set={setValues}
                            />

                            <Button title={values.id?'UPDATE':'ADD'} icon={'md-add-circle'} isloading={isLoading} onPress={() => Validate()}/>
                        </ScrollView>
                </Modal>
        )
    }



    return(
        <View style={styles.container}>
                <Button title={'ADD'} icon={'receipt'} onPress={() => setModalVisible(true)} color={'#04b8a9'}/>
                {AddModal()}
        </View>
    )

}

const styles = StyleSheet.create({
    container:{
        padding:10,
        flexDirection:'row',
        borderBottomWidth:1,
        borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
    selected:{
        padding:5,
        borderWidth:1,
        borderRadius:5,
        borderColor:'#aaa',
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
        backgroundColor:'#eee'
    }
  });
  