import {getFirestore,writeBatch,collection,doc,Timestamp,serverTimestamp,arrayUnion,deleteField} from 'firebase/firestore';
import {GETACC} from '../../getacc';
import {AddCredit} from "../index";
import moment from 'moment';


async function Init() {
	const db = getFirestore();
	const user = await GETACC();
	const batch = writeBatch(db);
	return {
		db,
		user,
		batch
	}
}


export const DeleteDelivery = async (data, type) => {

	const {
		db,
		user,
		batch
	} = await Init()

	const Ref_Order = doc(db, "account/" + user.id + "/" + type + "/" + data.id);
	batch.update(Ref_Order, {
		'driver': deleteField(),
		'proof': deleteField(),
		'delivery_status': deleteField(),
		'delivery_remark':deleteField(),
	})


	const Ref_Delivery = doc(db, "account/" + user.id + "/delivery/" + data.id);
	batch.delete(Ref_Delivery)

	delete data['driver']
	delete data['proof']
	delete data['delivery_remark']
	delete data['delivery_status']

	await batch.commit();

	return data

};

export const TrasporterCredit = async (picked, amount) => {

	const {
		db,
		user,
		batch
	} = await Init()

	let pickedId = picked.map(a => a.id);

	var credit = {}

	delete picked[0].transporter['in-house']
	delete picked[0].transporter['company_transporter']

	credit['category'] = {
		...picked[0].transporter,
		'category': "Transport",
		'name': 'Transporter (' + picked[0].name + ')',
		'sub_category': 'Partner',
	}
	
	credit['partner_id'] = picked[0].driver_id;
	credit['date'] = new Date();
	credit['status'] = 'active'
	credit['amount'] = parseFloat(amount)
	credit['delivered'] = pickedId
	const Credit = await AddCredit(credit)

	if (Credit) {
		pickedId.forEach(el => {
			const Ref = doc(db, "account/" + user.id + "/delivery/" + el);
			batch.update(Ref, {
				'paid_id': Credit.id
			})
		});
		await batch.commit();

		return {
			'credit': Credit.id,
			'ids': pickedId
		}
	}

	return null

};



export const UpdateDriver = async (order,driver,type,shopee,update,action) => {

	const {db,user,batch} = await Init()


	if(!driver.id) {
		// Transporter List Add Driver Details
			driver['in-house'] = false
			let Ref_Driver = doc(collection(db, "account/" + user.id + "/transporter_list"))
			batch.set(Ref_Driver, { ...driver,
				['createdAt']: serverTimestamp(),
				['status']: 'active'})
			driver['id'] = Ref_Driver.id
	}else{
		// Transporter List Update Driver Details
			let Ref_Driver = doc(db, "account/" + user.id + "/transporter_list/" + driver.id)
			batch.update(Ref_Driver, driver)
	}


	const Delivery = DeliveryDb(order,driver,type,shopee,action)

	if(update == 'delivery' && !shopee){
		Delivery['delivery'] = order.address
		Delivery['customer_mobile'] = order.customer_mobile
	}

	// Delivery Db update data

	const Ref_Delivery = doc(db, "account/" + user.id + "/delivery/" + order.id);

	if(update == 'order' && !order.driver){
		// Delivery List Add New Job
		batch.set(Ref_Delivery, { ...Delivery,
			['createdAt']: serverTimestamp()
		})
	}else{
		// Delivery List Update Job
		batch.update(Ref_Delivery, Delivery)
	}
	

	// Order Update Driver Details

	
	const Ref_Order = doc(db, "account/" + user.id + "/" + type + "/" + order.id);
	batch.update(Ref_Order, {
		'delivery_status' : Delivery.delivery_status,
		'driver' : driver,
		'delivery' : order.address,
		'delivery_remark' : Delivery.delivery_remark,
		...ShopeeFormatter(shopee)
	})


	await batch.commit();

	order['driver'] = driver
	order['delivery'] = order.address
	const DeliveryProgresss = order.delivery_status ? order.delivery_status : []
	order['delivery_status'] = [...DeliveryProgresss, {
		'status': action?action:order.status,
		'updated': Timestamp.now()
	}]

	if(update == 'delivery'){
		Delivery['delivery_status'] = order.delivery_status
		Delivery['createdAt'] = Timestamp.now()
		Delivery['id'] = order.id
		return {...Delivery,...ShopeeFormatter(shopee)}
	}
	
	return {...order,...ShopeeFormatter(shopee)}

};


function ShopeeFormatter(shopee){
	if(shopee){ 
		const ShopeeF  = {}
		ShopeeF['name']  = shopee.name
		ShopeeF['mobile']  = shopee.mobile
		ShopeeF['delivery']  = shopee.address
		return ShopeeF
	}else{
		return null
	}
}



function DeliveryDb(order,driver,type,shopee,action){
	
	const Delivery  = {}

	if(shopee){
		Delivery['customer_name']  = shopee.name
		Delivery['customer_mobile']  = shopee.mobile
		Delivery['delivery']  = shopee.address
	}else{
		Delivery['customer_name']  = order.name
		Delivery['customer_mobile']  = order.mobile
		Delivery['delivery']  = order.address
	}

	Delivery['name'] = driver.name
	Delivery['mobile'] = driver.mobile
	Delivery['priority'] = 0
	Delivery['status'] = order.status
	Delivery['delivery_status'] = arrayUnion({
		'status': action?action:order.status,
		'updated': Timestamp.now()
	})


	Delivery['type'] = type
	Delivery['company_transporter'] = driver['in-house']
	Delivery['driver_id'] = driver.id
	Delivery['transporter'] = driver
	Delivery['last_updated'] = serverTimestamp()
	Delivery['paid_id'] =  order.paid_id ? order.paid_id : null;
	Delivery['delivery_remark'] = order.delivery_remark?order.delivery_remark:null



	return Delivery
}
