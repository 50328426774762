import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,Alert, ActivityIndicator,FlatList,ScrollView} from 'react-native';
import {Ionicons,MaterialIcons} from '@expo/vector-icons';
import COLORS from "../../../../assets/component/colors";
import {ValidMobile} from "../../../../assets/js/validate";
import {Button,Modal,Form} from "../../../../assets/component";
import {AddOrder,UpdateQuotation,AddQuotation,GetAllDocs,GetCustomerDataByMobile,UpdateOrder,UpdateData,AddCreditNote} from "../../../../assets/js/firebase";
import ProductsList from './productslist'
import Products from './comp/products'

export default function AddNewOrder(props) {

    const {add,data,visible,setvisible,update,quotation,creditnote} = props

    const [values, setValues] = useState({});
    const [prev, setPrev] = useState({});


    const [modalProducts, setModalProducts] = useState(true);
    const [isLoading, setIsloading] = useState(false);
    const [error, setError] = useState("");
    const [selected, setSelected] = useState([]);
    const [productList, setProductList] = useState(null);



    useEffect(() => {
      if(data && (update ||  creditnote)){
        setPrev(data)
        setValues(data)
        setSelected(data.products)
        setModalProducts(false)
      }else{
        setModalProducts(true)
        setValues({})
      }
  }, [visible]);



  useEffect(() => {
    const getDatas = async () => {
      const ListProduct = await GetAllDocs('products','active')
      setProductList(ListProduct)
    }
    getDatas()
  }, []);





      const onChangeHandler = (e,name) => {
        const Value = e.target?e.target.value:e
        setValues((prevState) => ({ ...prevState, [name]: Value }));
  
      };

      async function Validate(){
        setError('')


        const valid = Validation(['name','mobile','date']);
        const Mobile = ValidMobile(values.mobile)

   
        if(valid && Mobile){
          setIsloading(true)
          selected.forEach(e => {delete e.stock,delete e.createdAt});
          const NewVal = {...values}
          NewVal['mobile'] = Mobile
          NewVal['products'] = selected
          NewVal['amount'] = selected.reduce((n, {amount,qty}) => n + (parseFloat(amount)*parseInt(qty)), 0).toFixed(2)


          var Result 
          if(update){
            if(quotation){
              Result = await UpdateQuotation(NewVal,prev.id)
            } else if (creditnote) {
              Result = await UpdateData('credit_note',prev.id,NewVal)
            }else{
              Result = await UpdateOrder(NewVal,prev.id,prev.products)
            }
          }else{
            if(quotation){
              Result = await AddQuotation(NewVal)
            }else if (creditnote) {
              NewVal['invoice_id'] = prev.id
              Result = await AddCreditNote(NewVal)
            }else{
              Result = await AddOrder(NewVal)
            }
          }
          add(Result)
          clear()

        }else{
          if(!Mobile){
            setValues((prevState) => ({ ...prevState, ['mobile']: {error:"Not Valid Mobile"} }));
          }
        }

    }


    function Validation(obj){
      var validated = true
      obj.map((name)=>{
          if(!values[name] || values[name].error){
              setValues((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
              validated = false
          }
      })
      return validated
  }








    function clear(){
      setSelected([])
        setValues({})
        setIsloading(false)
        setvisible(false)
    }




    const Error = (props) => {
        if(!props.error) return null
        return (
            <View style={styles.center}><MaterialIcons name='error-outline' size={18} color={COLORS.Error} /><Text> {props.error}</Text></View>
        )
    }





    function SelectProduct(data) {
      setSelected(data)
      setModalProducts(false)
    }




    const Footer = () => {
      return <Button title={update?'UPDATE':'ADD'} icon={'md-add-circle'} onPress={() => Validate()} isloading={isLoading}/>
    }




    const OrderAddPopup = (data) => {
        return(
                <View style={{flex:1}}>
                        <Products data={data} set={setSelected} list={setModalProducts} status={values.status} creditnote={creditnote}/>
                        <View >
                                <Form 
                                      data={
                                                  [
                                                      [{name:'name',display:'Name'},{name:'company',display:'Company',flex:1}],
                                                      [{name:'mobile',display:'Mobile',mobileno:true},{name:'search',color:'#dc3545',icon:'search',button:true,'onPress':CustomerByMobile,isLoading:isLoading}],
                                                      [{name:'address',display:'Address',line:4},{name:'delivery',display:'Delivery Address',line:4}],
                                                      [{name:'remark',display:'Remark',line:2}],
                                                      [{name:'date',display:'Date',date:true}],
                                                  ]
                                          }
                                      value={values}
                                      set={setValues}
                                  />
                                <Error error={error}/>
                                {Footer()}
                        </View>
                </View>
        )
    }



    
    async function CustomerByMobile(){
      const Mobile = ValidMobile(values.mobile)
      if(Mobile){
          setIsloading(true)
          const Route = quotation ? 'quotations' : 'orders'
          const CDetails = await GetCustomerDataByMobile(Mobile,Route)
          if(CDetails[0]){
            const {name,address,company,delivery} = CDetails[0]
            const obj = {name,address,company,delivery}
            const result = Object.keys(obj).reduce((r,key) => (obj[key] && (r[key]=obj[key]), r),{})
            setValues({...values,...result})
            onChangeHandler(Mobile,'mobile')
          }else{
            setValues({})
            onChangeHandler(Mobile,'mobile')
          }
          setIsloading(false)
      }
    }
    



    return (
      <Modal title={update?'Update Order':'Add Order'} icon={'ios-receipt'} visible={visible} setVisible={clear} headColor={'#428ff5'} back={true}>

            {modalProducts?<ProductsList setValue={(val)=>SelectProduct(val)} selected={selected} products={productList}/>:OrderAddPopup(selected)}

      </Modal>
    )

}

const styles = StyleSheet.create({
    container:{
      flexDirection:'row',
      flexGrow:1,
    },
    center:{
        flexDirection:'row',
        alignContent:'center',
        padding:15,
        alignItems:'center'
    },

      orderSelect:{
        padding:5,
        flexDirection:'row',
        borderBottomWidth:1,
        borderStyle:'dashed',
        borderColor:'#cecece'
      },
      additem:{
        borderWidth:1,
        padding:5,
        marginVertical:5,
        flex:1,
        borderStyle:'dashed',
        marginRight:10,
        borderRadius:5,
        borderColor:COLORS.Primary,
        alignItems:'center',
        alignContent:'center',
        flexDirection:'row',
        justifyContent:'center'
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Primary,
        fontSize:14
      },
      th:{
        fontWeight:'600',
        color:COLORS.Primary
      },
  });
  