import React, { useState,useEffect } from "react";
import {View,StyleSheet,Switch, ActivityIndicator} from "react-native";
import {ImagePicker,TextInput,ListDown,ListDownoverlay,Text,DatePicker,FilePicker} from "./index";
import { TouchableOpacity } from "react-native-gesture-handler";
import Ionicons from '@expo/vector-icons/Ionicons';


export default function FormComponent(props) {

    const {data,value,set} = props;


    const [custom, setCustom] = useState(false);     

    useEffect(() => {

      if(!value){
        ExtraArrays(data)
      }


    }, [data]);


    function ExtraArrays(data){
      var Value = {}
      data.map((val,i)=>{
        val.map((val2,ix)=>{
          Value[val2.value] = ''
        })
      })
      set(Value)
    }



  const onChangeHandler = (e,name,numeric) => {
      const Value = e.target?e.target.value:e
      if(numeric){
            const Interger = Value.replace(/[^0-9]/g, '');
            set((prevState) => ({ ...prevState, [name]: Interger }));
      }else{
        set((prevState) => ({ ...prevState, [name]: Value }));
      }
  };

  const onChangeHandler2 = (e,ix,key,name) => {
    const Value = e.target?e.target.value:e
    const updatedAreas = [...value[key]];
    updatedAreas[ix][name] = Value;

   set((prevState) => ({ ...prevState, [key]: updatedAreas }));
  };

  const onChangeHandlerMobile = (e,name) => {
    const Value = e.target?e.target.value:e
    const phone = Value.replace(/[^0-9]/g, '');
    set((prevState) => ({ ...prevState, [name]: '+'+phone }));
};


    
  const InputVal = (ix,props) => {
    const {flex,display,name,numeric,remark,line,editable} = props
    const inputVal = value && value[name] ? value[name]:''
    return (
      
      <View key={ix} style={{flex:flex?flex+1:1,paddingLeft:ix > 0 && 10}} >
                    <TextInput
                        multiline
                        title={display}
                        placeholder={display}
                        maxLength={144}
                        numberOfLines={line}
                        onChange={e => onChangeHandler(e,name,numeric)}
                        value={inputVal}
                        editable={editable}
                        remark={remark}
                        />
      </View>
    )
}


const InputMobile = (ix,props) => {
  const {flex,display,name,numeric,remark,line,editable} = props
  const inputVal = value && value[name] ? value[name]:''
  return (
    
    <View key={ix} style={{flex:flex?flex+1:1,paddingLeft:ix > 0 && 10}} >
                  <TextInput
                      title={display}
                      placeholder={display}
                      maxLength={144}
                      numberOfLines={line}
                      onChange={e => onChangeHandlerMobile(e,name,numeric)}
                      value={inputVal}
                      editable={editable}
                      remark={remark}
                    />
    </View>
  )
}

const InputImage = (ix,props) => {
  const {name,numeric,size,format} = props
  const inputVal = value && value[name] ? value[name]:''
  return (
    <View key={ix} style={{paddingLeft:ix > 0 && 10}} >
      <ImagePicker set={e => onChangeHandler(e,name,numeric)} value={inputVal} accept={format} size={size}/>
    </View>
  )
}

const InputFile = (ix,props) => {
  const {flex,name} = props
  const inputVal = value && value[name] ? value[name]:''
  return (
    <View key={ix} style={{flex:flex?flex+1:1,paddingLeft:ix > 0 && 10}} >
      <FilePicker value={inputVal} set={e => onChangeHandler(e,name)} />
    </View>
  )
}

const InputDate = (ix,props) => {
  const {name,display} = props
  const inputVal = value && value[name] ? value[name]:''
  return (
    <View key={ix} style={{paddingLeft:ix > 0 && 10}} >
      <DatePicker set={e => onChangeHandler(e,name)} value={inputVal} title={display}/>
    </View>
  )
}

const InputButton = (ix,props) => {
  const {icon,title,onPress,color,iconsize,isLoading} = props

  if(isLoading) return <ActivityIndicator size={'small'} key={ix}/>

  
  return (
    <View key={ix} style={{justifyContent:'center',alignContent:'center',paddingTop:!title && 15}}>
      <TouchableOpacity onPress={onPress} style={{paddingVertical:5,paddingHorizontal:10}}>
            <Ionicons name={icon} size={iconsize?iconsize:18} color={color?color:'#aaa'} /> 
            <Text>{title}</Text>
      </TouchableOpacity>
    </View>
  )
}

const InputToggle = (ix,props) => {
  const {name,display} = props
  const inputVal = value && value[name] ? value[name]:''
  return (
    <View key={ix} style={{justifyContent:'center',alignContent:'center'}}>
        <View style={{flexDirection:'row',flex:1}}>
            <Text style={{flex:1,fontWeight:'400'}}>{display}</Text>
            <Switch
                trackColor={{false: '#767577', true: '#81b0ff'}}
                thumbColor={value ? '#4971a4' : '#f4f3f4'}
                ios_backgroundColor="#3e3e3e"
                onValueChange={e => onChangeHandler(!inputVal,name)}
                value={inputVal}
            />

        </View>
        <Text style={{fontSize:12,color:'#aaa'}}>* {display}</Text>
    </View>
  )
}




const InputOthers = (ix,props) => {
  const {name,list,display} = props
  const inputVal = value && value[name] ? value[name]:''
  var Value = []
  if(inputVal.length){
      inputVal.map((val2,ix3)=>{
                    var Sub = []
                    list.map((val3,ix2)=>{
                      Sub.push(InputVal2(ix2,ix,name,val2[val3],val3))
                    })
                    Value.push(<View key={ix3} style={{flexDirection:'row',alignItems:'center'}}>{Sub}{DelBtn(ix3,name)}</View>)

      })
      return (
            <View key={ix} style={{flex:1,padding:10}}>
              {AddBtn(ix,display,name,list)}
              {Value}
            </View>
        )
  }else{
      return AddBtn(ix,display,name,list)
  }
}

const DelBtn = (ix,name) => {
  return (
  <TouchableOpacity onPress={()=>RemoveMore(ix,name)} style={{padding:4}}>
     <Ionicons name={'trash'} size={18} color={'#aaa'} /> 
  </TouchableOpacity>
  )
}

const AddBtn = (ix,title,name,list) => {
  return (
    <TouchableOpacity key={ix} style={styles.addbtn} onPress={()=>{Addmore(name,list)}}>
      <Text style={styles.breaker}>{'+'+title}</Text>
    </TouchableOpacity>
  )
}

const InputVal2 = (i,ix,key,val,name) => {
  return (
   <View key={i+ix} style={{flex:1,paddingLeft:i > 0 && 10}}>
    <TextInput  onChange={e => onChangeHandler2(e,ix,key,name)} value={val} placeholder={name}/>
  </View>
  )
}

const InputListDown = (ix,props) => {

  const {name,listdown,display,flex} = props
  const inputVal = value && value[name] ? value[name]:''
  return (
    <View key={ix} style={{flex:flex?flex+1:1,paddingLeft:ix > 0 && 10}} >
      <ListDown list={listdown} active={inputVal} onPress={e => onChangeHandler(e,name)} title={display}/>
    </View>
  )
}



const InputContent = (ix,props) => {
  const {content} = props
  return <View key={ix} style={{flex:1}}>{content}</View>
}


const InputListDownOverlay = (ix,props) => {
  const {name,listdownoverlay,display,flex,addnew} = props
  const inputVal = value && value[name] ? value[name]:''

  return (
    <View key={ix} style={{flex:flex?flex+1:1,paddingLeft:ix > 0 && 10}} >
        <ListDownoverlay list={listdownoverlay} active={inputVal} onPress={e => onChangeHandler(e,name)} title={display} addnew={addnew}/>
    </View>
  )
}


const LineBreaker = (ix,title) => {
  return (
    <View key={ix} style={styles.bxbreaker} >
      <Text style={styles.breaker}>{title}</Text>
    </View>
  )
}

function Addmore(name,list){

    if(value[name] && value[name].every(v => v.name !== '' && v.amount !== '')){
          const Addarr = list.reduce((a, v) => ({ ...a, [v]: ''}), {})
          const updatedAreas = [Addarr,...value[name]];
          set((prevState) => ({ ...prevState, [name]: updatedAreas }));
    }else{
      const Addarr = list.reduce((a, v) => ({ ...a, [v]: ''}), {})
      const updatedAreas = [Addarr];
      set((prevState) => ({ ...prevState, [name]: updatedAreas }));
    }

}

function RemoveMore(ix,name){
      const updatedAreas = [...value[name]];
      updatedAreas.splice(ix, 1);
      set((prevState) => ({ ...prevState, [name]: updatedAreas }));
}


const DisplayValue = () => {
      return (
        data.map((val,i)=>{
          var Value = []
          val.map((val2,ix)=>{
            const {name,image,listdown,listdownoverlay,date,others,button,toggle,file,mobileno,content} = val2

            if (!name) {
              Value.push(LineBreaker(ix,val2))
            } else if (date) {
              Value.push(InputDate(ix,val2))
            } else if (others) {
              Value.push(InputOthers(ix,val2))
            } else if (image) {
              Value.push(InputImage(ix,val2))
            }
            else if (file) {
              Value.push(InputFile(ix,val2))
            } else if (listdown) {
              Value.push(InputListDown(ix,val2))
            } else if (listdownoverlay) {
              Value.push(InputListDownOverlay(ix,val2))
            } else if (button) {
              Value.push(InputButton(ix,val2))
            } else if (toggle) {
              Value.push(InputToggle(ix,val2))
            } else if (content) {
              Value.push(InputContent(ix,val2))
            } else if (mobileno) {
              Value.push(InputMobile(ix,val2))
            }  else {
              Value.push(InputVal(ix,val2))
            }

          })
          return <View key={i} style={styles.row}>{Value}</View>
        })
      )
}



    


      return(
        <View style={styles.container} {...props}>
          {DisplayValue()}
        </View>
      )


  }


    const styles = StyleSheet.create({
      container:{
        padding:5
      },
      row:{
        flexDirection:'row',
      },
      breaker:{
        color:'#c13a3a',
        fontWeight:'3400'
      },
      bxbreaker:{
        flex:1,
        marginVertical:5,
        borderBottomWidth:1,
        borderColor:'#ddd',
        paddingBottom:5,
        marginHorizontal:5
      },
      addbtn:{
        borderWidth:1,
        padding:4,
        borderColor:'#ccc',
        borderRadius:5,
        margin:2,
        alignContent:'center',
        alignItems:'center',
        backgroundColor:'#efefef'
      }
    
    });

  