import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,TouchableOpacity,Text,FlatList,ActivityIndicator,Alert} from 'react-native';
import {Ionicons,MaterialCommunityIcons} from '@expo/vector-icons';
import moment from 'moment'
import UpdateDriverModal from './update';
import {CheckBox} from "../../../assets/component";


export default function Table(props) {

  const {tab,setList,list,next,loading,setPicked,picked} = props

  const [driver, setDriver] = useState(null);

  useEffect(() => {
    setPicked([])

  }, []);



  const Th = (title,size,center) => {
    return(
        <View style={{flex:size,alignItems:center && 'center'}}>
            <Text style={styles.item}>{title}</Text>
        </View>

    )
}


    const Header = () => {
      return(
        <View style={{flexDirection:'row',borderBottomWidth:1,padding:5,borderColor:'#ccc'}}>
          {Th('Driver',3)}
          {Th('Status',1,true)}
        </View>
      )
    }

    const Loader = (loading) => {
      if(!loading) return null
      return (
        <View style={{alignContent:'center',alignItems:'center',padding:10}}>
          <ActivityIndicator size={'small'} color={'#aaa'} />
        </View>
      )
    }



    const EcommerceIcon = (item) => {
      return (
        <View style={{flexDirection:'row',flex:1,borderRightWidth:0.5,borderColor:'#ddd',marginRight:5}}>
            {item.type == 'online_order' && <MaterialCommunityIcons name='laptop' size={15} color={'#aaa'} style={{marginRight:4}}/>}
            <Text style={styles.id}>{item.id}</Text>
        </View>
      )
    }

    const DaysIcon = (Days) => {
      return (
        <View  style={{flexDirection:'row',flex:1,borderRightWidth:0.5,borderColor:'#ddd',marginRight:5}}>
          <Ionicons name='time-outline' size={15} color={'#bbb'} style={{marginRight:4}}/>
          <Text style={[styles.id]}>{Days} Days</Text>
        </View>
      )
    }

    const StatusIcon = (status,count) => {
      return (
        <View style={{flex:3,alignItems:'center',flexDirection:'row'}}>
                    {CircleCount(status,count)}
                    <Text style={[styles.item,{fontSize:12}]}>{count}</Text>
        </View>
      )
    }

    const CircleCount = (num,stat) => {
      if(stat == 'delivered') return  <Ionicons name='checkmark-circle' size={15} color={'#75ae8f'} style={{marginRight:5}}/>
      const Bgcolor = ["delivered","pickup"].includes(stat) ? '#e1dbd2' : '#ffb94c'
      return (
        <View style={[styles.countbx,{backgroundColor:Bgcolor,marginRight:5}]}>
          <Text style={styles.counttxt}>{num}</Text>
        </View>
      )
    }

    const Remark = (data) => {
      if(!data) return null
      return (

        <View style={{flexDirection:'row',alignContent:'center',alignItems:'center'}}>
            <Ionicons name='information-circle-outline' size={14} color={'#ff7c2a'} style={{marginRight:4}}/>
            <Text style={{color:'#ff7c2a',fontSize:12}}>{data}</Text>
         </View>
      )
    }

    const DeliveryAdd = (data) => {
      var GetLastIndex = data.lastIndexOf(",");
      const  State = data.substring(GetLastIndex + 1);

      var before = data.substring(0, data.indexOf(State));

      if(before.length > 130){
        before = '...'+before.substr(data.length - 80)
      }
      return (
        <Text style={{flexDirection:'row'}}><Text style={[styles.item,{fontWeight:'200',fontSize:12}]}>{before}</Text>{StateIcon(State)}</Text>
      )
    }

    const StateIcon = (state) => {
      return (
        <View style={styles.stateicon}>
          <Text>{state.trim()}</Text>
        </View>
      )
    }

    const Thickbox = (data) => {
      var containsId = picked.find(x => x.id === data.id);

      if(tab !== 'pending') return null
      return (
          <View style={{alignItems:'center'}}>
            <CheckBox value={containsId} onValueChange={()=>SelectId(data,containsId)} />
            {PriorityBx(data.priority)}
          </View>
      )
    }

    function SelectId(data,found){
      if(found){
        const filteredData = picked.filter(item => item.id !== data.id);
        setPicked(filteredData);
      }else{
        setPicked([...picked, data])
      }
    }

    const PriorityBx = (prior) => {
      if(prior < 1) return null
      return (
        <View style={{padding:5}}>
          <Ionicons name='notifications-outline' size={14} color={'#ff7c2a'} style={{marginRight:4}}/>
        </View>
      )
    }

    const DriverName  = (item) => {
      const TxtColor  = item.company_transporter ? '#15a097' : '#777'
      return (
        <Text style={[styles.title,{color:TxtColor}]}>{item.name}</Text>
      )
    }




    const RenderItem = ({item,index}) => {
      //#65d883
      const createdAt = moment(item.createdAt.toDate());
      const Days =  moment().diff(createdAt, 'days')
      const GetLastStatus = item.delivery_status.at(-1).status
      const StatusCount = item.delivery_status.length
      const BgColor =  Days > 5 ? '#faebe6' : index % 2 == 0  ? "#FFFFFF" : "#f2f2f2"


      return(
        <TouchableOpacity onPress={() => setDriver(item)}  style={{padding:5,paddingVertical:15, backgroundColor:BgColor }}>
          <View style={{flexDirection:'row'}}>
                  {Thickbox(item)}
                  <View style={{flex:3}}>
                        <View style={{flexWrap:'wrap'}}>
                            {DriverName(item)}
                            {DeliveryAdd(item.delivery)}
                            {Remark(item.delivery_remark)}
                            <View style={{flexDirection:'row',borderTopWidth:0.5,padding:2,borderColor:'#ddd',marginTop:5}}>
                                {EcommerceIcon(item)}
                                {DaysIcon(Days)}
                                {StatusIcon(StatusCount,GetLastStatus)}
                            </View>
                        </View>
                  </View>
          </View>
        </TouchableOpacity>
      )
  }



  function UpdateData(data){

    const Tab = data.status !== tab ? data.status : tab

    const nlist = {...list}

    if(data.status !== tab){
      const filteredData = nlist[tab].filter(item => item.id !== data.id);
      nlist[tab] = filteredData
      nlist[Tab].unshift(data)
    }


    const result = nlist[Tab].map((obj) => obj.id === data.id ? data : obj);
    setList({...nlist, [Tab]:result})
    

  }

  function CancelOrder(id){
    const filteredData = list[tab].filter(item => item.id !== id);
    setList({...list, [tab]:filteredData});
  }
  



    

    return(
      <View style={styles.container}>
          {Header()}
          {Loader(loading)}
          <FlatList
          contentContainerStyle={{ paddingBottom: 120 }}
            showsHorizontalScrollIndicator={false}
            data={list[tab]}
            renderItem={RenderItem}
            onEndReachedThreshold={0}
            onEndReached={next}
          />
          <UpdateDriverModal data={driver} clear={setDriver} update={(val)=>UpdateData(val)} cancel={CancelOrder} modal={'delivery'}/>
      </View>
    )



}

const styles = StyleSheet.create({
  container:{
    flex: 1,
  },
  flexcenter:{
    alignContent:'center',
    flexDirection:'row',
    alignItems:'center',
  },
  item:{
    marginRight:5,
    color:'#777',
  },
  id:{
    marginRight:5,
    color:'#006ad4',
    fontSize:12,
  },
  title:{
    color:'#777',
    marginRight:5,
    fontWeight:'500'
  },
  option:{
    position:'absolute',
    backgroundColor:'#fff',
    zIndex:1,
    left:-90,
    borderRadius:5,
    padding:10,
    paddingHorizontal:20,
    shadowOffset: { width: 2, height: 4 },
        shadowRadius: 10,
        shadowOpacity: 0.2,
        elevation: 15,
  },
  counttxt:{
    fontSize:10,
    color:'#fff'
  },
  countbx:{
    width:15,
    height:15,
    borderRadius:10,
    alignContent:'center',
    alignItems:'center',
    justifyContent:'center',
  },
  date:{
    fontSize:12,
    color:'#03a9f4'
  },
  stateicon:{
    borderRadius:5,
    backgroundColor:'#d9d9d9',
    paddingHorizontal:5
  }
});
