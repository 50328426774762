import React, { useState,useEffect } from 'react';
import { StyleSheet ,View,Text,TouchableOpacity,ActivityIndicator,Linking,Image} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import {ValidMobile} from "../../../../assets/js/validate";
import {Copy,Modal,Button,TextInput,Form} from "../../../../assets/component";
import COLORS from "../../../../assets/component/colors";
import {CURRENT_ACC,GetAllDocs} from "../../../../assets/js/firebase";
import Storage from '../../../../assets/js/storage';
import {Invoice} from '../../../../assets/js/encyrpt';
import Searchinput from './search';
import Progress from "./component/progress";
import DOLINK from "./component/dolink";
import PROOF from "./component/proof";
import SuggestList from './component/suggest';
import {UpdateDriver,DeleteDelivery} from "../../../../assets/js/firebase/delivery";


export default function DeliveryModal(props) {

    const {clear,data,update,type,modal,cancel} = props

    const orderType = data && data.type?data.type:type
    
    const BankLIST = ['CASH','MAYBANK','CIMB','BANK ISLAM','AFFIN','ALLIANCE','AMBANK','BANK RAKYAT','HLB','HSBC','MUAMALAT','OCBC','PBB','RHB','UOB','BSN']

    const [isLoading, setIsloading] = useState(false);
    const [values,setValues] = useState({})
    const [driver,setDriver] = useState({})

    const [transporterList, setTransporterList] = useState([]);

    useEffect(() => {
      getTransporters()
    }, []);


    useEffect(() => {

      if(modal == 'order'){ SetOrder(data)}
      if(modal == 'delivery'){ SetDelivery(data)}

    }, [data]);

    function SetOrder(data){
      if(data){
        if(data.driver){
          setDriver(data.driver)
        }else{
          setDriver({})
        }
        setValues(data)
        if(data.delivery){
          changeHandler(data.delivery,'address')
        }
      }
    }

    function SetDelivery(data){
      if(data){

        // differents
       if(data.transporter){
         setDriver(data.transporter)
       }else{
         if(data.name){
           setDriver({'name':data.name,'mobile':data.mobile,'id':data.driver_id,'in-house':data.company_transporter})
         }else{
           setDriver({})
         }
       }
       
       setValues(data)
       if(data.delivery){
         changeHandler(data.delivery,'address')
       }
     }
    }



    async function getTransporters(){

        const user = await CURRENT_ACC();
        const dbName = user.id+'transporter'
        const ItemList = await Storage.getData(dbName)
        const ParseData = JSON.parse(ItemList)
        if(ParseData){
            setTransporterList(ParseData);
        }else{
            const DataList  = await GetAllDocs('transporter_list')
            Storage.storeData(dbName,JSON.stringify(DataList))
            setTransporterList(DataList);
        }
      
    };


    async function StoreCatLocal(data){
      const user = await CURRENT_ACC();
      const CatName = user.id+'transporter'

      const StoreVal = JSON.stringify(data)
      await Storage.storeData(CatName,StoreVal)
  }


    function changeHandler (val,type){
      setValues( prevValues => {
        return { ...prevValues,[type]: val}
      })
    }

    function changeHandlerDriver (val,type){
      setDriver( prevValues => {
        return { ...prevValues,[type]: val}
      })
    }

    function ShopeeFormat(data){
      var regex = /\b['6']\d{8,12}/
      let output = data.match(regex)
      if(!output || !output.length){ return null }
      const Mobile = output && ValidatePhone(output[0],'mobile')
      const AfterMobile = data.split(output[0]).pop().trim();
      const BeforeMobile = data.split(output[0]).shift().replaceAll(',', '').trim()
      return {'name':BeforeMobile,'mobile':Mobile,'address':AfterMobile}
    }


    


    async function Validate(){
      setIsloading(true)
      const Mobile = ValidatePhone(driver.mobile,'mobile')
      const ValidField = Validation(['address']);

      if(Mobile && ValidField){
          driver['mobile'] = Mobile

          const Shopee = ShopeeFormat(values.address)

          const Action  = values.status =='pending' && 'Assign Driver (' + driver.name + ')'

          const Result = await UpdateDriver(values,driver,orderType,Shopee,modal,Action)
          if(Result){
            UpdateTransporter(Result)
            update(Result)
            ClearState()
          }
      }
      setIsloading(false)

    }


    function ValidatePhone(data,change) {
      const Input = ValidMobile(data);
      if(Input){
        changeHandlerDriver(Input,change)
        return Input
      }else{
        changeHandlerDriver({error:"Invalid Value"},change)
        return null;
      }
    }

    function UpdateTransporter(data){
      const Driver = modal == 'order' ? data.driver : data.transporter
      const NewObj =  [...transporterList.filter((obj) => obj.id !== Driver.id), {...Driver}];
      StoreCatLocal(NewObj)
      setTransporterList(NewObj)
    }

    function Validation(obj){
      var validated = true
      obj.map((name)=>{
          if(!values[name] || values[name].error){
              setValues((prevState) => ({ ...prevState, [name]: {error:"Required"} }));
              validated = false
          }
      })
      return validated
    }


    function ClearState(){
      setIsloading(false)
      clear(null)
    }

    async function VerifyRemove(){
      if (confirm('Are you sure you want to cancel delivery ?')) {
        RemoveOrder()
      } else {
        console.log('Cancel Delete');
      }
  }

  async function RemoveOrder(){
    setIsloading(true)
    const status = await DeleteDelivery(data,orderType)
    if(status){
      if(modal == 'order'){
        update(status)
      }
      if(modal == 'delivery'){
        cancel(status.id)
      }
      ClearState()
    }else{
      console.log("Error Remove")
    }
    setIsloading(false)
  }




    function SetDriver(data){
      data['id'] = data.driver_id
      delete data.driver_id
      setDriver(data);
    }


    const CatCol = () => {

      const Arr = [
          [{name:'name',display:'Name'}],
          [{name:'name',display:'Name',content:<SuggestList data={transporterList} values={driver} set={(val)=>SetDriver(val)}/>}],
          [{name:'mobile',display:'Driver Mobile',mobileno:true}],
      ]

      if(driver && driver.id) return Selected(driver)
      return (
          <Form 
                      data={Arr}
                      value={driver}
                      set={setDriver}
          />
      )
  }

  const Selected = (data) => {
      const {name,mobile,bank} = data
      return (

        <View style={styles.selected}>
          <View style={{flex:1}}>
            <Text style={{fontsize:16}}>{name}</Text>
            <View style={styles.catrow}>
              <Text style={styles.cat}>{mobile}</Text>
              <Ionicons name='chevron-forward' size={12} color={'#aaa'} />
              <Text style={styles.sub}>{bank}</Text>
            </View>
            </View>
              <TouchableOpacity style={{padding:5}} onPress={()=>CancelDriver({})}>
                      <Text>X</Text>
              </TouchableOpacity>
        </View>
      )

  }

  function CancelDriver(){
    if(data.paid_id){
      if (confirm('Driver are paid do you sure want to delete ?')) {
        setDriver({})
      } 
    }else{
      setDriver({})
    }
  }

  const StatusList = () => {
    if(modal == 'order' || data.status == 'delivered') return null
    return (
        <Form 
            data={[
                    [{name:'status',display:'Status',flex:1,listdown:['pending','pickup','delivered']}],
                  ]}
            value={values}
            set={setValues}
        />      
    )
  }

  const ButtonRow = () => {
    return (
      <View style={{flexDirection:'row',flexWrap:'wrap',marginTop:10}}>
          {DelBtn()}
          <View style={{flex:1}}/>
          <Button title={'UPDATE'} isloading={isLoading} icon={'md-add-circle'} onPress={() => Validate()}/>
      </View>
    )
}

const DelBtn = () => {
  if(!driver.id || data.proof) return null
  return <Button isloading={isLoading} color={'red'} icon={'md-trash'} onPress={() => VerifyRemove()}/>
}

const OrderLink = () => {
  return (
    <View style={{flexDirection:'row'}}>
      <Text style={{fontSize:18}}>Order ID :</Text>
      <TouchableOpacity onPress={()=> OpenInvoice()} >
        <Text style={{fontSize:18,color:'#005cff'}}>{data.id}</Text>
      </TouchableOpacity>
    </View>
  )
}

async function OpenInvoice(){
  const Link = await Invoice(data.id,orderType)
  window.open(Link, '_blank')
}



function WhatsappLink(){
  
  const {name,mobile} = values.transporter

  var Whatsapp = '** 🎱 DONKEEZ SPORTS **'+'%0a%0a'

    Whatsapp += '🔔 Delivery Notification'+'%0a%0a'

    Whatsapp += (1)+'. 📍'+values.delivery
    Whatsapp += "%0a . *MOBILE* : "+mobile.replace('6', '')
    Whatsapp += "%0a . *NAME* : "+name +"%0a%0a"

    Whatsapp += '%0a'+'> Please sign all DO with photo of proof on the link for each order'

    Linking.openURL('whatsapp://send?text='+Whatsapp+'&phone='+values.customer_mobile)
}

function Call(){
  Linking.openURL(`tel:${values.customer_mobile}`)
}

    if(!data) return null


    const Proof = data.proof?false:true
    const MobileDetails = modal == 'delivery' ? 'customer_mobile' : 'mobile'

        return (
                <Modal title={!Proof?'Completed':'Update Driver'} icon={'truck'}  Iconfont={'MaterialCommunityIcons'} visible={data?true:false} setVisible={()=>ClearState()}>
                        <OrderLink/>
                        {CatCol()}
                        <Form 
                                data={[
                                            ['BANK'],
                                            [{name:'bank',display:'Bank',listdown:BankLIST},{name:'account',display:"Account"},{name:'customer_id',display:'SSM/MYKAD'}],
                                        ]}
                                value={driver}
                                set={setDriver}
                            />

                          <Form 
                                data={[
                                            [{name:'address',display:'Address',line:3}],
                                            [ {name:MobileDetails,display:'Customer Mobile',mobileno:true},
                                              {name:'call',color:'#399539',icon:'call',button:true,'onPress':Call},
                                              {name:'whatsapp',color:'#399539',icon:'logo-whatsapp',button:true,'onPress':WhatsappLink}],
                                            [{name:'delivery_remark',display:'Remark'}],
                                        ]}
                                value={values}
                                set={setValues}
                          />
                        <DOLINK data={data} type={orderType} modal={modal}/>
                        <PROOF data={data}/>
                        <Progress data={data.delivery_status}/> 
                        <StatusList/>      
                        <ButtonRow/>
                </Modal>
        )

    

}

const styles = StyleSheet.create({
    container:{
      padding:10,
      flexDirection:'row',
      flexGrow:1,
      borderBottomWidth:1,
      borderColor:'#ccc'
    },
    header:{
        borderBottomWidth:1,
        borderColor:'#aaa',
    },
    btn:{
        flexDirection:'row',
        alignContent:'center',
        padding:2,
        marginHorizontal:5,
        alignItems:'center',
        justifyContent:'center'
    },
    title:{
        fontSize:18,
        color:'#444',
        flex:1
    },
    btnAdd:{
        backgroundColor:'#0d6efd',
        padding:10,
        borderRadius:5,
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center'
      },
      orderSelect:{
        borderRadius:5,
        padding:5,
        borderColor:'#aaa',
        flexDirection:'row',
        backgroundColor:'#eee'
      },
      input2:{
        width:50,
      },
      deliverybtn:{
        padding:10,
        alignContent:'center',
        alignItems:'center'
      },
      deliverytxt:{
        color:COLORS.Link,
        fontSize:14
      },
      link:{
        color:COLORS.Link,
        fontSize:16,
        marginLeft:2
      },
      cat:{
        fontSize:12,
        color:'#0076a8',
        marginRight:3
      },
      sub:{
        fontSize:12,
        color:'#aaa',
        marginLeft:3
      },
      catrow:{
        flexDirection:'row',
        alignItems:'center',
        alignContent:'center'
      },
      selected:{
        flexDirection:'row',
        padding:10,
        borderWidth:0.5,
        borderRadius:5,
        borderColor:'#aaa',
        alignContent:'center',
        backgroundColor:'#efefef',
        marginVertical:10
    }
  });
  